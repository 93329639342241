@use '../../../scss/' as *;
.img-box {
    @include transition3;
    position: relative;
    padding: 20px 20px 68px;  
    background: #000000;
    &::after {
        @include transition3;
        position: absolute;
        content: "";
        border: 1px solid rgba(288, 63, 51, 0.5);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    &::before {
        @include transition3;
        position: absolute;
        content: "";
        border: 2px solid rgba(288, 63, 51, 0.5);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    &.active,
    &:hover {
        box-shadow: $box-shadow;
        &::before {
            border-color: #E43F33;
            border: 2px solid #E43F33;
        }
    }
    .content {
        position: absolute;
        bottom: 18px;
        left: 20px;
        z-index: 1;
        a {
            font-size: 20px;
            line-height: 1.4;
            text-transform: uppercase;
            color: $white;
            font-family: 'cybox', sans-serif;
            &:hover {
                color: $color-hover;
            }
        }
    }
    &:hover {
        cursor: context-menu;
    }
}

.home-2 .img-box {
    margin-bottom: 30px;
}

.page-nft {
    .img-box {
        margin-bottom: 32px;
        
    }
    .tf-button {
        margin-top: 0;
    }
}
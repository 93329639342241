@use '../../../scss/' as *;

.team-box {
    @include transition3;
    position: relative;
    padding: 0px 0 20px 0;
    background: $bg-4;
    overflow: hidden;
    &::after {
        @include transition3;
        position: absolute;
        content: "";
        border: 1px solid rgba(288, 63, 51, 0.5);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &::before {
        @include transition5;
        position: absolute;
        content: "";
        border: 2px solid rgba(288, 63, 51, 0);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &.active,
    &:hover {
        box-shadow: $box-shadow;
        &::before {
            border-color: #E43F33;
            border: 2px solid #E43F33;
        }
    }
    .image {
        z-index: -2;
        padding: 2px;
        img {
            max-height: 310px;
            width: 100%;
        }
    }
    .content {
        position: relative;
        z-index: 10;
        padding: 16px 20px 0 20px;
        .h8 {
            margin-bottom: 5px;
            line-height: 1.4;
            font-weight: 400;
            text-transform: uppercase;
            font-family: "cybox", sans-serif;
        }
        p {
            margin-bottom: 19px;
            font-size: 14px;
            line-height: 1.57;
            text-transform: capitalize;
        }
    }
    .social {
        @include flex2;
        li {
            margin-right: 12px;
            a {
                @include flex(center,center);
                width: 28px;
                height: 28px;
                background: rgba(288, 63, 51, 0.4);
                border-radius: 8px;
                color: #fff;
                font-size: 14px;
                &:hover {
                    background: #E43F33;;
                }
            }
        }
    }

}

.home-2 .team-box {
    margin-bottom: 30px;
}